.main-body {
    background-image: url("../img/frontclothes.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
}

.main-body h1 {
    font-size: 4rem;
    color: white;
    padding-top: 150px;
}

.main-body-two {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

.line {
    max-width: 100px;
    margin: 100px auto 0 auto;
    border-bottom: 3px solid white;
}

.finder {
    display: flex;
    justify-content: space-evenly;
}

.finder h3 {
    text-shadow: 2px 2px black;
}

.finder button {
    outline: none;
    border-radius: 10px;
    padding: 10px;
    background: black;
    color: white;
    border: 1px solid black;
    text-transform: uppercase;
    margin-top: 50px;
}

.logo {
    width: 5%;
}

.navbar-brand {
    font-size: 30px !important;
}

.nav-link {
    font-size: 20px;
}

.content {
    max-width: inherit !important;
}

.about-body h1{
    color: white;
    text-shadow: 2px 2px black;
}

.about-body p{
    font-size: 30px;
    color: white;
    text-shadow: 2px 2px black;
    max-width: 1140px;
    margin: 0 auto;
}

.about-content {
    height: 470px;
    background-color: orange;
}

.about-content p{
    color: white;
    text-align: center;
    padding: 50px 50px 0 50px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 20px;
}

.about-content div {
    text-align: center;
} 

.about-content button, .refresher{
    padding: 20px;
    background: black;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid black;
    margin-top: 20px;
    color: white;
}

.about-content button a, .finder button a{
    color: white;
    text-decoration: none;

}

.team-body {
    background-color: orange;
}

.ProfileTest {
    background-color: orange;
    height: 100%;
    width: 100%;

}

.square {
    margin: 2vw;
}
  